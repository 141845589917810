<template>
  <div>
    <titulo-seccion>
      <template slot="area-rutas">
        <router-link to="/">Inicio</router-link> / <span class="active">Noticias</span>
      </template>
      <template slot="area-titulo">
        <h3 class="text-center">Noticias</h3>
      </template>
    </titulo-seccion>

    <div id="content-page" class="container">
      <div class="row justify-content-between">
        <div class="col-lg-4 col-md-4 col-sm-6" v-for="item in items" :key="item.id">
          <div class="box-noticia" data-aos="fade-up">
              <div v-if="item.imagen" class="portada" v-lazy:background-image="item.imagen">
                <!-- <img v-lazy="item.imagen" class="img-fluid img-responsive" :alt="item.titulo" :title="item.titulo">                 -->
              </div>
              <div class="body">
                <small class="date">
                  <i class="icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7 11H9V13H7V11ZM21 6V20C21 21.1 20.1 22 19 22H5C4.46957 22 3.96086 21.7893 3.58579 21.4142C3.21071 21.0391 3 20.5304 3 20L3.01 6C3.01 4.9 3.89 4 5 4H6V2H8V4H16V2H18V4H19C20.1 4 21 4.9 21 6ZM5 8H19V6H5V8ZM19 20V10H5V20H19ZM15 13H17V11H15V13ZM11 13H13V11H11V13Z" fill="#666666"/>
                    </svg>
                  </i> 
                  {{ item.publicado }}
                </small>
                <p class="font-weight-bold">
                  <router-link :to="'/noticia/' + item.url">
                    {{ item.titulo }}
                  </router-link>
                </p>
                <div class="item-descripcion">
                  <small v-html="item.descripcion"></small>
                </div>
                <router-link class="my-3 btn btn-sm btn-fiscalia" :to="'/noticia/' + item.url">
                  Leer más 
                  <i class="icon">
                    <svg width="12" height="18" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.7008 8.16086L5.60348 0.178257C5.55668 0.117395 5.49696 0.083313 5.43402 0.083313H4.00571C3.88629 0.083313 3.83141 0.307284 3.92179 0.424138L9.57369 7.8249H0.129112C0.0581006 7.8249 0 7.91255 0 8.01966V9.48034C0 9.58746 0.0581006 9.6751 0.129112 9.6751H9.57207L3.92018 17.0759C3.8298 17.1952 3.88467 17.4167 4.0041 17.4167H5.48082C5.51149 17.4167 5.54215 17.3996 5.56475 17.368L11.7008 9.33914C11.7567 9.26585 11.8015 9.17534 11.8322 9.0737C11.8629 8.97207 11.8788 8.86169 11.8788 8.75C11.8788 8.63831 11.8629 8.52793 11.8322 8.4263C11.8015 8.32466 11.7567 8.23415 11.7008 8.16086Z" fill="white"/>
                    </svg>
                  </i>
                </router-link>                
              </div>
            </div>          
        </div>
      </div>
      <div id="navegacion" class="mt-5 mb-5 text-center row">
        <b-pagination-nav
              use-router
              v-model="currentPage"
              :link-gen="linkGen"
              :number-of-pages="totalRows"
              :per-page="perPage"
              first-number
              last-number
              align="center"
              class="my-0"
          />
      </div>
    </div>
  </div>
</template>

<script>
import axiosIns from '@/libs/axios';
import TituloSeccion from '../secciones/TituloSeccion.vue'
// import VueSocialSharing from 'vue-social-sharing'

import { BPaginationNav } from 'bootstrap-vue'

export default {
  components: {
    TituloSeccion,
    BPaginationNav
  },
  data(){
        return{
          items:[],
          perPage: 10,
          totalRows: 1,
          currentPage: 1,
        }
  },
  metaInfo: {
      title: 'Ministerio Público',
      titleTemplate: '%s | Noticias',
      htmlAttrs: {
          lang: 'es-ES'
      },
  },
  created(){     
      if (Object.keys(this.$route.query).length === 0 ) {
          this.currentPage=1;
          this.listar();
      }else{
          this.currentPage=this.$route.query.page;
          this.listar();
      }  
  },
  watch: {
      $route: function () {
          if (Object.keys(this.$route.query).length === 0 ) {
              this.currentPage=1;
              this.listar();
          }else{
              this.currentPage=this.$route.query.page;
              this.listar();
          }
      },
    },
  methods: {
    linkGen(pageNum) {
        return pageNum === 1 ? '?' : `?page=${pageNum}`
    },
    listar(){
        var url= '/web/noticias?page='+this.currentPage;
        axiosIns.get(url)
        .then(res => {
            this.items = res.data.data;
            this.totalRows = res.data.last_page            
        })
        .catch(err =>{
            console.log(err);
        });
    },
  }
}
</script>

<style>
  
</style>
